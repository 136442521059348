import { ref, computed } from "vue";

export function useImageProperties(props, imageKey, imageComponent) {
	const croppedImage = ref(null);

	const imageSrc = computed(() => {
		const mediaSrc = props.settings[imageKey];
		return mediaSrc && typeof mediaSrc === "object"
			? mediaSrc.src
			: mediaSrc;
	});

	const imageAlt = computed(() => {
		const mediaSrc = props.settings?.[imageKey];
		return mediaSrc && typeof mediaSrc === "object"
			? mediaSrc.alt
			: props.settings?.alt || "";
	});

	const croppedSrc = computed(() => {
		return backgroundMediaCrop.value?.image
			? croppedImage.value
			: imageSrc.value; //mediaHelper.getEncodedURI(imageSrc.value)
	});

	const imageHeight = computed(() => {
		return (
			props.settings?.extraProps?.height || props.settings.height || null
		);
	});

	const imageWidth = computed(() => {
		return (
			props.settings?.extraProps?.width || props.settings.width || null
		);
	});

	const mediaBackgroundPosition = computed(() => {
		return backgroundMediaFocal.value?.bgPosition || "center";
	});

	const backgroundMediaFocal = computed(() => {
		return backgroundMediaCrop.value?.focal;
	});

	const backgroundMediaCrop = computed(() => {
		const mediaSrc = props.settings?.[imageKey] || "";
		const { crop, focal } = mediaSrc;
		if (crop || focal) {
			const mediaObject = { ...crop };
			if (focal) mediaObject.focal = focal;
			return mediaObject;
		}
		return props.settings?.mediaMetadata?.[imageKey];
	});

	const updateCroppedImage = (croppedImg) => {
		if (!imageComponent.value) imageComponent.value = {};
		if (!croppedImg) {
			return (imageComponent.value[imageKey] = imageSrc.value);
		}
		croppedImage.value = croppedImg;
		imageComponent.value[imageKey] = croppedImg;
	};

	return {
		imageAlt,
		imageSrc,
		croppedImage,
		croppedSrc,
		imageHeight,
		imageWidth,
		mediaBackgroundPosition,
		backgroundMediaFocal,
		backgroundMediaCrop,
		updateCroppedImage,
	};
}
